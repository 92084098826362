import React, { useRef, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules


const Whatweoffer = () => {
  return (
    <>
      <div className="business">
            <div className="container">
            <div className="business-wrapper align-items-center">
         <div className="business-slider">
          <div className="swiper-business">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={false}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                              
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 10,
                         },
                         580: {
                       slidesPerView: 1,
                       spaceBetween: 10,
                         },
                         700: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 1,
                       spaceBetween: 0,
                          },
                          1024: {
                    slidesPerView: 1,
                        spaceBetween: 50,
                           },
                           1241: {
                    slidesPerView: 2,
                        spaceBetween: 20,
                           },
                           1440: {
                    slidesPerView: 2,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
          <div className="item">
         <h4>Whole Life Insurance</h4>
         <p>Whole life insurance is a type of life insurance that covers you lifetime. It has a guaranteed death benefit, a fixed premium, and a cash value that grows at a fixed rate.</p>
        </div>
         </SwiperSlide>
         <SwiperSlide>
          <div className="item">
         <h4>Term Life Insurance</h4>
         <p>Term life insurance provides coverage for a specific duration with fixed premiums and guaranteed death benefit, but no cash value, making it a cost-effective choice.</p>
        </div>
         </SwiperSlide>
         <SwiperSlide>
          <div className="item">
         <h4>IUL</h4>
         <p>Indexed Universal Life Insurance is permanent life insurance with cash value, death benefit, interest linked to stock market index, flexible premiums, and minimum interest guarantee.</p>
        </div>
         </SwiperSlide>
         <SwiperSlide>
          <div className="item">
         <h4>Retirement Planning</h4>
         <p>Retirement can be a time to travel or spend time with family. It can be time to start a new business or begin a charitable endeavour. It can be purchased with the living benefits.</p>
        </div>
         </SwiperSlide>
                     
         <SwiperSlide>
          <div className="item">
         <h4>Financial Education</h4>
         <p>Everyone wants to know how money works and how you can make it work for you. If you don’t understand the power of money, then you can’t put it to work for you.</p>
        </div>
         </SwiperSlide>
                             
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Whatweoffer;