import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";
import { Helmet } from 'react-helmet';
import headphone from '../assets/images/insurance/headphone.png';
import Ourclient from "../component/section/Ourclient";
import xg from '../assets/images/insurance/xg.png';
import Whatweoffer from '../component/section/whatweoffer';
import policv from "../assets/images/insurance/policv.mp4";
import agentteam from '../assets/images/insurance/agentteam.png';
import verify from '../assets/images/insurance/verify.png';
import hasslefree from '../assets/images/insurance/hasslefree.png';
import weakness from '../assets/images/insurance/weakness.png';
import hours from '../assets/images/insurance/hours.png';
import phonecall from "../../src/assets/images/insurance/phonecall.png";
import HeaderSix from "../component/layout/header-6";
import Mynewform from "../component/section/mynewform";

const Insurancead = () => {
    return (
        <Fragment>
        <HeaderSix />
      <Helmet>
<title>Best Insurance and Financial Planning Agency – My Policy Planner</title>
<meta name="description" content="Secure your future with My Policy Planner. We offer comprehensive insurance and financial planning services tailored to your needs." />
</Helmet>
 {/* Start Slider area  */}
 <section className='myhome bg-overlay2'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xxl-6 col-xl-6 col-md-6 col-lg-5 col-12'>
                            <div className='exploring'>
                                <h1><span className="redspn">Choosing Wisely </span>Unveiling the Best Life Insurance Policies<br /> <span className='Massive'>Starting at <span className="redspn">$0.99/day.*</span></span></h1>
                            <div class="btn-wrapper animated go-top"><a class="theme-btn-1 btn btn-effect-1" href="tel:+1 844-203-4105"><span><img src={phonecall} alt="" className="phonecall" /></span>CONTACT US</a></div>
                            </div>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-md-6 col-lg-7 col-12' id="Massive-lead">
                          <div className='bnrwhitebox2 text-center' >
                          <div className='mynewform'>
                          <Mynewform />
   
            </div>
                          </div> 
                          <div className='ctabnr'>
                          <span className='ctabnrimg'>
                          <img src={headphone} alt="" className="support1" />
                          </span>
                        <span className='ctabnrone'>Get 30 Mintues Free Consulation</span> <br />
                        <span className="Now">Call Now:</span>
                        <span className='ctabnrtwo'><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></span>
                          </div>
                            </div>        
                        </div>
                    </div>
                </section>
          <Ourclient />
                <section className='pningabt' id='abtus'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='affordable text-center'>
                <h2>Why Choose My Policy Planner</h2>
                <p>We are a trusted online life insurance agency based in Texas, USA, with over <br />10 years of experience. We specialize in providing personalized life insurance solutions <br />to clients nationwide. </p>
                 </div>
                </div>
                </div>
              <div className='row'>
                <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
               <div className='Journey'>
     <span className='Journeyspan'>
      <img src={verify} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Trusted & Reliable</h3>
     <p> We partner with established companies for over a century, delivering quality service and products to our customers.</p>
    </div>
                </div>
                <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='Journey'>
     <span className='Journeyspan'>
      <img src={hasslefree} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Hassle Free Claim</h3>
     <p> Filing a claim is hassle-free with us. Just submit your documents, and we'll process it quickly, keeping you updated throughout.</p>
    </div>
       </div>
                <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='Journey'>
     <span className='Journeyspan'>
      <img src={weakness} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Independent & Unbiased</h3>
     <p>Unaffiliated with insurance companies, we provide unbiased advice and work solely for your benefit.</p>
    </div>
     </div>
                <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='Journey'>
     <span className='Journeyspan'>
      <img src={hours} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>24*7 Human Support</h3>
     <p>Our authorized experts are here to assist you, managing paperwork and guiding sound decisions.</p>
    </div>
                </div>
              </div>
               
            </div>
</section>
 
   


    <section className='business' id='whatweoffer'>
        <div className='container'>
            <div className='row'>
            <div className='col-xxl-6 col-xxl-6 col-lg-6 col-md-6 col-12'></div>
                <div className='col-xxl-6 col-xxl-6 col-lg-6 col-md-6 col-12'>
                    <h2>What we Offer</h2>
                    <p>Discover comprehensive insurance solutions tailored to your needs at My Policy Planner. From Life Insurance to Financial Planning, we offer competitive rates and personalized service.</p>
<div className='row' id='botmservce'>
<Whatweoffer />
</div>

                </div>
            </div>
        </div>
    </section>
    <section className='InsuranceSimplified' id='workprocess'>
        <div className='container'>
            <div className='row text-center'>
                <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                    <h4 className='Simplified'>Insurance Simplified</h4>
                    <h2 className='consetur'>How It Works</h2>
                    <p className='ipsum22'>Get personalized consultations, customized solutions, expert advice, streamlined applications,<br /> ongoing support, and periodic reviews. Experience a hassle-free insurance journey with us. </p>
                </div>
            </div>
            <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <div className='main-incididunt'>
                <div className='incididunt'>
                <h3>Find Your Policy</h3>
                <p className='SERVICESed'>Tell us about yourself, and we’ll match you to the available life insurance policies that meet your needs and work with your budget.</p>
                </div>
                <div className='incididunt1'>
                <h3>Get a Quote</h3>
                <p className='SERVICESed'>Select the insurance policy that works best for you. We've made it fast and easy to connect with one of our agents.</p>
                </div>
                <div className='incididunt2'>
                <h3>Apply and Buy</h3>
                <p className='SERVICESed'>Check getting life insurance off your to-do list. We'll walk you through the application and purchasing process. Coverage often starts the same day.</p>
                </div>
                </div>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                    <img src={xg} className='xg' alt='' />
                </div>
            </div>
        </div>
    </section>
    <section className='whyinsurance'>
        <div className='container'>
            <div className='row'>
                <div className='col-xxl-12 col-xl-12 col-lg-12 col-xl-12 col-md-12 col-12'>
                    <h2>Protecting Your Future and Loved Ones</h2>
                    <p className='ipsum02'>At My Policy Planner, we believe life insurance is more than financial protection. It's about securing your<br /> family's future and leaving a legacy. Safeguard their well-being and provide support in tough times. Explore<br />  our comprehensive life insurance solutions, tailored to your needs. Build a solid foundation for your family's <br /> future by contacting us today.</p>

                </div>
                </div>
                <div className='row' id='videosec'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-xl-6 col-md-12 col-12'>
                    <div className='rolbox-01'>
                    <p className='enim'>We prioritize your family's future. Secure their well-being with comprehensive life insurance solutions. Get a quote today and safeguard the future.</p>
                     <ul className='rolelist'>
                    <li><span><i class="fa fa-check" aria-hidden="true"></i></span>Security and peace of mind</li>
                    <li><span><i class="fa fa-check" aria-hidden="true"></i></span>Coverage for debts and expenses</li>
                    <li><span><i class="fa fa-check" aria-hidden="true"></i></span>Affordable and accessible options</li>
                    <li><span><i class="fa fa-check" aria-hidden="true"></i></span>Personalized to fit your needs</li>
                                      </ul>
                   <div class="btn-wrapper animated go-top"><a class="theme-btn-1 btn btn-effect-1" href="#Massive-lead">GET A QUOTE</a></div>
                 <img src={agentteam} className='agentteam' alt='' />
                    </div>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-xl-6 col-md-6 col-12'>
                    <div className='rolbox'>
                	<video src={policv}   controls
                        muted
                         autoPlay={"autoplay"}
                          preLoad="auto"
                          ></video>
                    </div>
                </div>
                </div>
          
        </div>
    </section>
   
    <section className='policy-faqs' id='faqs'>
        <div className='container'>
<div className='row text-center'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h2>Frequently Asked Questions?</h2>
        <p className='ipsum22'>Got questions about insurance? We have answers. Find information on coverage types, reducing<br /> premiums, filing claims, adjusting coverage, and getting started. </p>
    </div>
</div>
   <div className='row' id='forfaq'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                    <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
        >
 What is IUL Insurance?
        </button>
      </h2>
      <div
        id="collapseOne"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          IUL insurance stands for indexed universal life insurance. It is a type of permanent life insurance that offers flexible premiums, a death benefit, and a cash value that grows based on the performance of a stock market index. IUL insurance can provide protection, growth potential, and tax advantages.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
        >
   Why do I need life insurance?
        </button>
      </h2>
      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>Life insurance can provide financial protection and peace of mind for you and your loved ones. If you have dependents who rely on your income, life insurance can help them maintain their standard of living and achieve their goals in case you are no longer around. Life insurance can also help you leave a legacy for your family or a charity. </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
        >
  How much life insurance do I need?
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          The amount of life insurance you need depends on your personal and financial situation. Some factors to consider are your income, expenses, debts, assets, family size, future plans, and existing coverage.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFour">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
        >
        How can the policy accumulate cash value?
        </button>
      </h2>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>IUL policy pays earnings above the guaranteed minimum rate, that can be credited to the policy based partly on changes in these major stock indexes: the S&P 500® Index, the EURO STOXX 50P Index, and the Hang Seng Index. The different account options must be understood before you decide if this policy is right for you.</p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingFive">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
        >
 Are there tax advantages associated with Life Insurance?
        </button>
      </h2>
      <div
        id="collapseFive"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
     <p><b>Federal income tax-free death benefit:</b> <br />
There can be tax consequences when inheriting certain assets. Life Insurance provide a federal income tax-free death benefit.</p>

<p><b>Tax-deferred earnings</b> <br />
Customers have a greater potential for policy value accumulation since any cash value in the policy accumulates interest tax-deferred.</p>

<p><b>Tax-free withdrawals and loans*:</b> <br />
If the policy value is sufficient, customers can request withdrawals or loans to use for any purpose. Customers enjoy easy access to their policy value. Tax advantages only apply while the policy is in force. It could have adverse tax consequences if the policy is allowed to lapse.</p>

        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSix">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
        >
     What factors influence the cost of insurance?
        </button>
      </h2>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Your premium is determined by a number of factors. This includes your coverage needs and risk factors. Along with the amount of insurance coverage and the particular policy you choose, your age and gender are considered. Personal health history and family health history, such as chronic illness or hereditary diseases, can impact premiums. Personal habits and choices, like smoking or engaging in high-risk hobbies can lead to higher premiums. Your employment may also be considered, as some jobs are higher risk than others.

          </p>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
      <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSeven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSeven"
        >
  How does financial planning benefit me?
        </button>
      </h2>
      <div
        id="collapseSeven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Financial planning provides numerous benefits, including increased financial security, optimized investment strategies, retirement planning, debt management, tax efficiency, and protection against unforeseen events.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEight">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEight"
        >
   Can you help me with retirement planning?
        </button>
      </h2>
      <div
        id="collapseEight"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Yes, we specialize in retirement planning. Our experts will assess your retirement goals, create a tailored plan, and recommend suitable retirement accounts and investment options to secure your future.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingNine">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseNine"
        >
What types of investment options do you offer?
        </button>
      </h2>
      <div
        id="collapseNine"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We offer a wide range of investment options, including stocks, bonds, mutual funds, ETFs, and more. Our team will customize an investment portfolio based on your risk tolerance and financial objectives.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTen"
        >
How do you charge for your financial planning services?
        </button>
      </h2>
      <div
        id="collapseTen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our fees are transparent and agreed upon upfront. We offer fee-only or fee-based services, ensuring no hidden commissions or conflicts of interest.
          </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingEleven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEleven"
        >
Can you help with estate planning?
        </button>
      </h2>
      <div
        id="collapseEleven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Absolutely. We provide estate planning services to preserve and distribute your assets according to your wishes while minimizing taxes and probate.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTweleve">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTweleve"
        >
   How often should I review my financial plan?
        </button>
      </h2>
      <div
        id="collapseTweleve"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We recommend reviewing your financial plan at least annually or whenever significant life events occur, such as marriage, children, job changes, or inheritances.
          </p>
        </div>
      </div>
    </div>
  </div>
                </div>
            </div>
        </div>
    </section>
            <FooterThree />
        </Fragment>
    );
}
 
export default Insurancead;