import { Fragment } from "react";
import FooterThree from "../component/layout/footer-3";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../../src/component/sidebar/pagination";
import support1 from "../../src/assets/images/insurance/support1.png";
import agentteam from "../../src/assets/images/insurance/agentteam.png"
import blogimg1 from "../assets/images/blog/blogimg1.webp";
import blogimg2 from "../assets/images/blog/blogimg2.webp";
import blogimg3 from "../assets/images/blog/blogimg3.webp";
import blogimg4 from "../assets/images/blog/blogimg4.jpg";
import blogimg5 from "../assets/images/blog/blogimg5.jpg";
import blogimg6 from "../assets/images/blog/blogimg6.jpg";
import blogimg7 from "../assets/images/blog/blogimg7.jpg";
import blogimg9 from "../assets/images/blog/blogimg9.jpg";
import blogimg8 from "../assets/images/blog/blogimg8.jpg";
import blogimg10 from "../assets/images/blog/blogimg10.jpg";
import { Helmet } from 'react-helmet';
import HeaderSix from "../component/layout/header-6";
const BlogPage = () => {
    return (
        <Fragment>
  <HeaderSix />
       <Helmet>
<title>Explore Insights: Dive into Our Financial Blogs | My Policy Planner</title>
<meta name="description" content="Discover valuable tips and trends on our financial blog. Stay informed and empowered with My Policy Planner's expert articles." />
</Helmet>
       <PageHeader title={'Our Blog Classic Posts'} curPage={'Blog classic'} />
       <section className='contct-below'>
    <div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='ctabnr'>
         <span className='ctabnrimg'>
         <img src={support1} alt="" className="support1" />
        </span>
       <span className='ctabnrone'>Chat with an agent? Call now: </span>
        <span className='ctabnrtwo'><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></span>
        <img src={agentteam} className='newagent' alt='' />
        </div>
      
    </div>
   
    </div>
    </div>

</section>
                 <div className="blog-section padding-tb section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture">
                 <img src={blogimg1} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture">
                <h4>The Crucial Role of Life Insurance: Safeguarding Your Loved Ones' Future</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/UnlockingFinancialFreedomThePowerofFinancialPlanning">
                <img src={blogimg2} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/UnlockingFinancialFreedomThePowerofFinancialPlanning">
                <h4>Unlocking Financial Freedom: The Power of Financial Planning</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/UnlockingFinancialFreedomThePowerofFinancialPlanning">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/KeyTermsWhenComparingTermLifeInsurancePlans">
                <img src={blogimg3} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/KeyTermsWhenComparingTermLifeInsurancePlans">
                <h4>Key Terms When Comparing Term Life Insurance Plans</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/KeyTermsWhenComparingTermLifeInsurancePlans">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                          </div>
                          <div className="row" id="newrowblog">
                          <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner">
                 <img src={blogimg4} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner">
                <h4>Wealth Management:Securing Your Financial Future with My Policy Planner</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/RiskManagementSafeguardingYourFinancialFuturewithMyPolicyPlanner">
                 <img src={blogimg5} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/RiskManagementSafeguardingYourFinancialFuturewithMyPolicyPlanner">
                <h4>Risk Management-Safeguarding Your Financial Future with My Policy Planner</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/RiskManagementSafeguardingYourFinancialFuturewithMyPolicyPlanner">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner">
                 <img src={blogimg6} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner">
                <h4>Investment Management: Secure Your Financial Future with My Policy Planner</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                          </div>
             <div className="row" id="newrowblog">  
             <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection">
                 <img src={blogimg7} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection">
                <h4>Safeguarding Your Future: Understanding the Importance of Asset Protection</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>  
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner">
                 <img src={blogimg4} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner">
                <h4>Tax Management: A Crucial Aspect of Financial Planning with My Policy Planner</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/BusinessTransitionPlanningAStrategicGuideforLongTermSuccess">
                 <img src={blogimg9} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/BusinessTransitionPlanningAStrategicGuideforLongTermSuccess">
                <h4>Business Transition Planning: A Strategic Guide for Long-Term Success</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/BusinessTransitionPlanningAStrategicGuideforLongTermSuccess">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
             </div>   
       <div className="row" id="newrowblog">
       <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture ">
                 <img src={blogimg8} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture  ">
                <h4>Understanding Term Life Insurance: Your Financial Shield for the Future</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner">
                 <img src={blogimg10} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner">
                <h4>Understanding the Benefits of Whole Life Insurance: A Comprehensive Guide by My Policy Planner</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-xl-4 col-md-6 col-12">
                            <article>
                                <div className="section-wrapper">
                                
                         <div className="post-item">
                      <div className="post-inner">
                       <div className="post-thumb">
                <a href="/UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility">
                 <img src={blogimg2} className="blogimg" alt="Blog Thumb" />
                    </a>
                 </div>
              <div className="post-content">
                  <a href="/UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility">
                <h4>Universal Life Insurance: Your Key to Financial Security and Flexibility</h4>
          </a>
              <div className="meta-post">
          <ul className="lab-ul">
            <li>
              <i className="icofont-ui-user" />
             By Admin
            </li>
            <li>
              <i className="icofont-calendar" />
              Jun 05,2023
            </li>
          </ul>
        </div>
       
      </div>
      <div className="post-footer">
        <div className="pf-left">
          <a className="lab-btn-text" href="/UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility">
            Read More <i className="icofont-external-link" />
          </a>
        </div>
        <div className="pf-right">
          <i className="icofont-comment" />
          <span className="comment-count">3</span>
        </div>
      </div>
    </div>
  </div>
     </div> 
                            
                            </article>
                        </div>
         </div>     
                    <Pagination />
                </div>
            </div>
            <FooterThree />
        </Fragment>
    );
}
 
export default BlogPage;

