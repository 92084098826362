
import { Link } from "react-router-dom";
import React from "react";
import blogimg1 from "../../assets/images/blog/blogimg1.webp";
import blogimg2 from "../../assets/images/blog/blogimg2.webp";
import blogimg3 from "../../assets/images/blog/blogimg3.webp";
import blogimg4 from "../../assets/images/blog/blogimg4.jpg";

const PopularPost = () => {
    return (
        <div className="widget widget-post">
            <div className="widget-header">
                <h5 className="title">Latest Blogs</h5>
            </div>
            <ul className="widget-wrapper">
  <li className="d-flex flex-wrap justify-content-between">
    <div className="post-thumb">
      <a href="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture">
      <img src={blogimg1} className="blogimg" alt="Blog Thumb" />
        </a>
    </div>
    <div className="post-content">
      <a href="/TheCrucialRoleofLifeInsuranceSafeguardingYourLovedOnesFuture">
        <h6>The Crucial Role of Life Insurance: Safeguarding Your Loved Ones' Future</h6>
      </a>
      <p>Jun 05,2022</p>
    </div>
  </li>
  <li className="d-flex flex-wrap justify-content-between">
    <div className="post-thumb">
      <a href="/UnlockingFinancialFreedomThePowerofFinancialPlanning">
      <img src={blogimg2} className="blogimg" alt="Blog Thumb" />
      </a>
    </div>
    <div className="post-content">
      <a href="/UnlockingFinancialFreedomThePowerofFinancialPlanning">
        <h6>Unlocking Financial Freedom: The Power of Financial Planning</h6>
      </a>
      <p>Jun 05,2022</p>
    </div>
  </li>
  <li className="d-flex flex-wrap justify-content-between">
    <div className="post-thumb">
      <a href="/KeyTermsWhenComparingTermLifeInsurancePlans">
      <img src={blogimg3} className="blogimg" alt="Blog Thumb" />
      </a>
    </div>
    <div className="post-content">
      <a href="/KeyTermsWhenComparingTermLifeInsurancePlans">
        <h6>Key Terms When Comparing Term Life Insurance Plans</h6>
      </a>
      <p>Jun 05,2022</p>
    </div>
  </li>
  <li className="d-flex flex-wrap justify-content-between">
    <div className="post-thumb">
      <a href="/WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner">
      <img src={blogimg4} className="blogimg" alt="Blog Thumb" />
      </a>
    </div>
    <div className="post-content">
      <a href="/WealthManagementSecuringYourFinancialFuturewithMyPolicyPlanner">
        <h6>Wealth Management:Securing Your Financial Future with My Policy Planner</h6>
      </a>
      <p>Jun 05,2023</p>
    </div>
  </li>
</ul>

        </div>
    );
}
 
export default PopularPost;