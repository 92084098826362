import { Component, Fragment } from "react";




const HomeFour = () => {
    return (
        <Fragment>
      
			<div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/mypolicyplanner/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
				<li><a href="https://twitter.com/mypolicyplanner" title="Twitter"><i className="fab fa-twitter" /></a></li>
				<li><a href="https://www.instagram.com/mypolicyplanner/" title="Instagram"><i className="fab fa-instagram" /></a></li>
				<li><a href="https://www.tiktok.com/@my_policyplanner" title="Tiktok"><i class="fa-brands fa-tiktok"></i></a></li>
				<li><a href="https://www.linkedin.com/company/mypolicyplanner/" title="Linkedin"><i class="fa-brands fa-linkedin-in"></i></a></li>
				<li><a href="https://www.pinterest.com/mypolicyplanner/" title="Pintrest"><i class="fa-brands fa-pinterest-p"></i></a></li>
				
			</ul>
		</div>
       
        </Fragment>
    );
}
 
export default HomeFour;



