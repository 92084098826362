import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";
import support1 from '../../src/assets/images/insurance/support1.png';
import agentteam from '../../src/assets/images/insurance/agentteam.png';
import { Helmet } from 'react-helmet';
import HeaderSix from "../component/layout/header-6";

const Faq = () => {
    return (
        <Fragment>
       <HeaderSix />
            <Helmet>
<title>FAQ - Answers to Common Questions | My Policy Planner</title>
<meta name="description" content="Explore our FAQ page for clear insights into our services and financial planning. Get the information you need at My Policy Planner." />
</Helmet>
<section className='contactpge'>
    <div className='container'>
        <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <h2>FAQ</h2>
                <p>Got questions about insurance? We have answers. Find information on coverage types, <br />reducing premiums, filing claims, adjusting coverage, and getting started. </p>
            </div>
        </div>

    </div>
</section>
<section className='contct-below'>
    <div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='ctabnr'>
         <span className='ctabnrimg'>
         <img src={support1} alt="" className="support1" />
        </span>
       <span className='ctabnrone'>Chat with an agent? Call now: </span>
        <span className='ctabnrtwo'><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></span>
        <img src={agentteam} className='newagent' alt='' />
        </div>
      
    </div>
   
    </div>
    </div>

</section>

<section className='policy-faqs' id='faqs'>
        <div className='container'>
<div className='row text-center'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h2>Frequently Asked Questions?</h2>
        
    </div>
</div>
   <div className='row' id='forfaq'>
   <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                    <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
        >
 What is IUL Insurance?
        </button>
      </h2>
      <div
        id="collapseOne"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          IUL insurance stands for indexed universal life insurance. It is a type of permanent life insurance that offers flexible premiums, a death benefit, and a cash value that grows based on the performance of a stock market index. IUL insurance can provide protection, growth potential, and tax advantages.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
        >
   Why do I need life insurance?
        </button>
      </h2>
      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>Life insurance can provide financial protection and peace of mind for you and your loved ones. If you have dependents who rely on your income, life insurance can help them maintain their standard of living and achieve their goals in case you are no longer around. Life insurance can also help you leave a legacy for your family or a charity. </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
        >
  How much life insurance do I need?
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          The amount of life insurance you need depends on your personal and financial situation. Some factors to consider are your income, expenses, debts, assets, family size, future plans, and existing coverage.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFour">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
        >
        How can the policy accumulate cash value?
        </button>
      </h2>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>IUL policy pays earnings above the guaranteed minimum rate, that can be credited to the policy based partly on changes in these major stock indexes: the S&P 500® Index, the EURO STOXX 50P Index, and the Hang Seng Index. The different account options must be understood before you decide if this policy is right for you.</p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingFive">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
        >
 Are there tax advantages associated with Life Insurance?
        </button>
      </h2>
      <div
        id="collapseFive"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
     <p><b>Federal income tax-free death benefit:</b> <br />
There can be tax consequences when inheriting certain assets. Life Insurance provide a federal income tax-free death benefit.</p>

<p><b>Tax-deferred earnings</b> <br />
Customers have a greater potential for policy value accumulation since any cash value in the policy accumulates interest tax-deferred.</p>

<p><b>Tax-free withdrawals and loans*:</b> <br />
If the policy value is sufficient, customers can request withdrawals or loans to use for any purpose. Customers enjoy easy access to their policy value. Tax advantages only apply while the policy is in force. It could have adverse tax consequences if the policy is allowed to lapse.</p>

        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSix">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
        >
     What factors influence the cost of insurance?
        </button>
      </h2>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Your premium is determined by a number of factors. This includes your coverage needs and risk factors. Along with the amount of insurance coverage and the particular policy you choose, your age and gender are considered. Personal health history and family health history, such as chronic illness or hereditary diseases, can impact premiums. Personal habits and choices, like smoking or engaging in high-risk hobbies can lead to higher premiums. Your employment may also be considered, as some jobs are higher risk than others.

          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThirteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThirteen"
        >
When or why should I review my policy?
        </button>
      </h2>
      <div
        id="collapseThirteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          If your personal details, like your address or billing information change, you’ll want to update your policy. When your life situation changes, you’ll want to review your policy to ensure it still meets your beneficiary’s needs. Good times to review your policy are if you get married, grow your family, or change jobs.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFourteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFourteen"
        >
How can I lower my insurance premiums?
        </button>
      </h2>
      <div
        id="collapseFourteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          There are several ways to potentially reduce your premiums, such as bundling policies, maintaining a good credit score, and implementing safety measures. We can help identify available discounts and strategies to lower your insurance costs.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFifteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFifteen"
        >
What happens if I need to file a claim?
        </button>
      </h2>
      <div
        id="collapseFifteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We're here to guide you through the claims process. Our dedicated claims team will assist you in gathering the necessary information, filing the claim, and ensuring a smooth resolution. We'll be with you every step of the way.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSixteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSixteen"
        >
How do I get started with insurance coverage?
        </button>
      </h2>
      <div
        id="collapseSixteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Getting started is easy. Simply contact us, and our knowledgeable agents will schedule a consultation to assess your needs and recommend suitable coverage options. We'll guide you through the process from start to finish.
          </p>
        </div>
      </div>
    </div>
  
  </div>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
  <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSeven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSeven"
        >
  How does financial planning benefit me?
        </button>
      </h2>
      <div
        id="collapseSeven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Financial planning provides numerous benefits, including increased financial security, optimized investment strategies, retirement planning, debt management, tax efficiency, and protection against unforeseen events.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEight">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEight"
        >
   Can you help me with retirement planning?
        </button>
      </h2>
      <div
        id="collapseEight"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Yes, we specialize in retirement planning. Our experts will assess your retirement goals, create a tailored plan, and recommend suitable retirement accounts and investment options to secure your future.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingNine">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseNine"
        >
What types of investment options do you offer?
        </button>
      </h2>
      <div
        id="collapseNine"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We offer a wide range of investment options, including stocks, bonds, mutual funds, ETFs, and more. Our team will customize an investment portfolio based on your risk tolerance and financial objectives.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTen"
        >
How do you charge for your financial planning services?
        </button>
      </h2>
      <div
        id="collapseTen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our fees are transparent and agreed upon upfront. We offer fee-only or fee-based services, ensuring no hidden commissions or conflicts of interest.
          </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingEleven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEleven"
        >
Can you help with estate planning?
        </button>
      </h2>
      <div
        id="collapseEleven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Absolutely. We provide estate planning services to preserve and distribute your assets according to your wishes while minimizing taxes and probate.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTweleve">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTweleve"
        >
   How often should I review my financial plan?
        </button>
      </h2>
      <div
        id="collapseTweleve"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We recommend reviewing your financial plan at least annually or whenever significant life events occur, such as marriage, children, job changes, or inheritances.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEighteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEighteen"
        >
  What sets your financial planning company apart?
        </button>
      </h2>
      <div
        id="collapseEighteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our firm is dedicated to providing personalized, client-focused financial planning solutions. We are committed to your financial success, with experienced advisors, comprehensive services, and a fiduciary duty to act in your best interest.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingNineteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseNineteen"
        >
Are you licensed and registered to offer financial advice?
        </button>
      </h2>
      <div
        id="collapseNineteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Yes, our advisors are licensed and registered professionals with the necessary certifications and credentials to provide expert financial advice.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwenty">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwenty"
        >
How can I get started with your financial planning services?
        </button>
      </h2>
      <div
        id="collapseTwenty"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Simply contact us to schedule a consultation. We'll discuss your financial goals, assess your current situation, and create a tailored financial plan to set you on the path to financial success.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwenty">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwenty"
        >
Is financial planning only for wealthy individuals?
        </button>
      </h2>
      <div
        id="collapseTwenty"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          No, financial planning is essential for individuals of all income levels. It is a proactive approach to managing your finances and achieving financial goals, regardless of your current financial situation.
          </p>
        </div>
      </div>
    </div>
  </div>
                </div>
            </div>
        </div>
    </section>  
            <FooterThree />
        </Fragment>
    );
}
 
export default Faq;



