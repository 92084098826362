import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";
import insurncecenter from '../assets/images/insurance/insurncecenter.jpg';
import myinsure from "../assets/images/insurance/myinsure.png";
import capital from "../assets/images/insurance/capital.png";
import { Helmet } from 'react-helmet';
import listicon from  "../assets/images/insurance/listicon.png";
import protection from "../assets/images/insurance/protection.png";
import financialfreedom from '../assets/images/insurance/financialfreedom.png';
import portfolio from '../assets/images/insurance/portfolio.png';
import Ourclient from "../component/section/Ourclient";
import Mynewform from "../component/section/mynewform";
import HeaderSix from "../component/layout/header-6";
import bnrvideo from "../assets/images/insurance/bnrvideo.mp4";

const lifeinsurance = () => {
    return (
        <Fragment>
       <HeaderSix />
            <Helmet>
<title>Secure Your Future with the Best Life Insurance Agency</title>
<meta name="description" content="Discover the Best Life Insurance Agency solutions tailored to your needs. Partner with the experts at My Policy Planner for peace of mind." />
</Helmet>
 {/* Start Slider area  */}
 <section className='newlife'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
        <div className='newbnr text-center' id="talored">
          <h2>Instant life insurance</h2>
        <ul>
          <li>Trusted & Reliable</li>
          <li>Hassle Free Claim</li>
          <li>24*7 Human Support</li>
        </ul>        
        </div>
      </div>
        </div>
        <div className='row'>
        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
      <div className="bnr-video">
      <video
  src={bnrvideo}
  controls
  autoPlay
  preload="auto"
  width="640"
  height="360"
  loop
>
</video>
         {/* <video src={bnrvideo}   controls
            autoPlay={"autoplay"}
            preLoad="auto"></video> */}
               </div>
               </div>
       <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
       <div className="myleadform">
       <Mynewform />
       </div>
      </div>
</div>
        {/* <section className='Newleadform'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='Newleadform-outer-box'>
                <div className="cirlegroup">
  <span className="d-inline-block mr-2" />
  <span className="d-inline-block mr-2" />
  <span className="d-inline-block mr-2" />
</div>
<div className='mainform'>
<div className='container'>
    <div className='row'>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                  <div className="bnr-video">
                  <video src={bnrvideo}   controls
                     autoPlay={"autoplay"}
                 
                      preLoad="auto"
                          ></video>
                          </div>
             </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <div className='mynewform'>
            <Mynewform />
             </div>
            </div>
        </div>
    </div>
</div>
            </div>
            </div>
        </div>
    </div>
</section> */}
        </div>
 </section>
 <Ourclient />

 <section className='newwhychoose'>
  <div className='container'>
    <div className='row text-center'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
<h2>Why Choose My Policy Planner - <br /><span className='newhy'>Your Trusted Life Insurance Partner!</span></h2>
    </div>
    </div>
    <div className='row' id='Experienced'>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' id='rightneed'>
      <div className='Needs-box'>
        <h3>Tailored Coverage for Your Needs</h3>
        <p>Unique life insurance solutions crafted for you. Experienced agents listen, assess, and provide comprehensive coverage for you and your loved ones. My Policy Planner cares.</p>
        </div>
        <div className='Needs-box'>
        <h3>Expert Advice and Guidance</h3>
        <p>Life insurance made easy with our friendly agents. Get expert advice and answers to all your questions. Make informed decisions. Let's navigate together!</p>
        </div>
        <div className='Needs-box'>
        <h3>Competitive Pricing</h3>
        <p>Affordable life insurance, no compromise on coverage. Competitive pricing for best value. My Policy Planner cares about your investment.</p>
        </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12' id='whyimg'>
      <img src={insurncecenter} className='insurncecenter' alt='' />
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12' id='leftneed'>
      <div className='Needs-box'>
        <h3>Customer-Centric Approach</h3>
        <p>Clients come first. Outstanding service, trust, and reliability. From consultation to policy management, we build lasting relationships. Your needs are our priority.</p>
        </div>
        <div className='Needs-box'>
        <h3>Transparent and Ethical Practices</h3>
        <p>Transparency and ethics guide us. Clear, concise info on insurance products. Understand your coverage without hidden surprises. My Policy Planner cares.</p>
        </div>
        <div className='Needs-box'>
        <h3>Effortless Application Process</h3>
        <p>Easy life insurance application process. My Policy Planner ensures a smooth, hassle-free experience. Agents assist with information and paperwork. Simplify with us.</p>
        </div>
      </div>
    </div>
  </div>
</section>
 <section className='DIfferent-types'>
    <div className='container'>
    <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='insuranceplan text-center'>
              <h2>Different Types of Life Insurance</h2>
              <p className='ipsum22'> Life insurance is a fundamental component of a comprehensive financial plan. It offers invaluable protection, <br />ensuring that your loved ones are financially secure and supported in the event of your passing.</p>
            </div>
          </div>
    </div>
    <div className='row' id='their'>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={protection} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3><a href='/UnderstandingTermLifeInsuranceYourFinancialShieldfortheFuture'>Term Life Insurance</a></h3>
     <p>Provides coverage for a specified term and pays out a death benefit if the insured passes away during that period. It offers affordable, temporary protection.</p>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={financialfreedom} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3><a href='/UnderstandingtheBenefitsofWholeLifeInsuranceAComprehensiveGuidebyMyPolicyPlanner'>Whole Life Insurance</a></h3>
     <p>Offers lifelong coverage, a guaranteed death benefit, and a cash value component that grows over time. It provides lifelong security and potential savings.</p>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={portfolio} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3><a href='/UniversalLifeInsuranceYourKeytoFinancialSecurityandFlexibility'>Universal Life Insurance</a></h3>
     <p>Combines a death benefit with flexible premiums and the opportunity to accumulate cash value at a variable interest rate. It offers flexibility and potential growth.</p>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={capital} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3><a href='/UnveilingthePowerofIndexedUniversalLifeInsurancewithMyPolicyPlanner'>Indexed Universal Life Insurance</a></h3>
     <p>Combines a death benefit with cash value growth linked to the performance of a stock market index. It offers potential for higher returns while minimising downside risks.</p>
    </div>
    </div>
  </div>

  {/* <div className='row' id='their'>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={capital} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Indexed Universal Life Insurance</h3>
     <p>Combines a death benefit with cash value growth linked to the performance of a stock market index. It offers potential for higher returns while minimising downside risks.</p>
    </div>
    </div> */}
    {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={variable} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Variable Life Insurance</h3>
     <p>Provides a death benefit and allows policyholders to allocate premiums into various investment accounts. The cash value fluctuates based on the performance of the investments chosen.</p>
    </div>
    </div> */}
    {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={portfolio} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Final Expense Insurance</h3>
     <p>Designed to cover end-of-life expenses such as funeral costs and outstanding debts. It offers smaller death benefits and simpler underwriting processes.</p>
    </div>
    </div> */}
  {/* </div> */}
      {/* <div className='row' id='paper'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className="at-icon-box at-icon-left clearfix">
  <div style={{ width: 78 }} className="at-icon-box-icon">
    <i style={{ fontSize: 15 }} className="fa fa-paper-plane" />
  </div>
  <div className="at-icon-text">
    <h3 className="no-stripe">
    Term Life Insurance
    </h3>
    <p>
    Provides coverage for a specified term and pays out a death benefit if the insured passes away during that period. It offers affordable, temporary protection.
    </p>
  </div>
</div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className="at-icon-box at-icon-left clearfix">
  <div style={{ width: 78 }} className="at-icon-box-icon">
    <i style={{ fontSize: 15 }} className="fa fa-paper-plane" />
  </div>
  <div className="at-icon-text">
    <h3 className="no-stripe">
    Whole Life Insurance
    </h3>
    <p>
    Offers lifelong coverage, a guaranteed death benefit, and a cash value component that grows over time. It provides lifelong security and potential savings.
    </p>
  </div>
</div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className="at-icon-box at-icon-left clearfix">
  <div style={{ width: 78 }} className="at-icon-box-icon">
    <i style={{ fontSize: 15 }} className="fa fa-paper-plane" />
  </div>
  <div className="at-icon-text">
    <h3 className="no-stripe">
    Universal Life Insurance
    </h3>
    <p>
    Combines a death benefit with flexible premiums and the opportunity to accumulate cash value at a variable interest rate. It offers flexibility and potential growth.
    </p>
  </div>
</div>
        </div>
      </div>
      <div className='row' id='paper2'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className="at-icon-box at-icon-left clearfix">
  <div style={{ width: 78 }} className="at-icon-box-icon">
    <i style={{ fontSize: 15 }} className="fa fa-paper-plane" />
  </div>
  <div className="at-icon-text">
    <h3 className="no-stripe">
    Indexed Universal Life Insurance
    </h3>
    <p>
    Combines a death benefit with cash value growth linked to the performance of a stock market index. It offers potential for higher returns while minimising downside risks.
    </p>
  </div>
</div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className="at-icon-box at-icon-left clearfix">
  <div style={{ width: 78 }} className="at-icon-box-icon">
    <i style={{ fontSize: 15 }} className="fa fa-paper-plane" />
  </div>
  <div className="at-icon-text">
    <h3 className="no-stripe">
    Variable Life Insurance
    </h3>
    <p>
    Provides a death benefit and allows policyholders to allocate premiums into various investment accounts. The cash value fluctuates based on the performance of the investments chosen.
    </p>
  </div>
</div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className="at-icon-box at-icon-left clearfix">
  <div style={{ width: 78 }} className="at-icon-box-icon">
    <i style={{ fontSize: 15 }} className="fa fa-paper-plane" />
  </div>
  <div className="at-icon-text">
    <h3 className="no-stripe">
    Final Expense Insurance
    </h3>
    <p>
    Designed to cover end-of-life expenses such as funeral costs and outstanding debts. It offers smaller death benefits and simpler underwriting processes.
    </p>
  </div>
</div>
        </div>
      </div> */}
    </div>
   </section>
   <section className='comparing'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
          <div className='comparing-box'>
            <h2>Key Terms When Comparing Term Life Insurance Plans</h2>
            <p>Life insurance is a fundamental component of a comprehensive financial plan. It offers invaluable protection,
ensuring that your loved ones are financially secure and supported in the event of your passing.</p>
            <ul>
              <li><span><img src={listicon} className='listicon' alt='' /></span>Total Payout of each plan</li>
              <li><span><img src={listicon} className='listicon' alt='' /></span>Premium amount paid for desired Total Payout</li>
              <li><span><img src={listicon} className='listicon' alt='' /></span>Policy term offered</li>
              <li><span><img src={listicon} className='listicon' alt='' /></span>High claim settlement ratio</li>
              <li><span><img src={listicon} className='listicon' alt='' /></span>Riders offered with the plan</li>
            </ul>
            <div class="btn-wrapper animated go-top"><a class="theme-btn-1 btn btn-effect-1" href="">GET A QUOTE</a></div>
          </div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <div className='imge-box '>
       
        <h3>Call us today about our Life Insurance options</h3>
        <p>Our experts understand your needs</p>
        <div class="btn-wrapper animated go-top"><a class="theme-btn-1 btn btn-effect-1" href="">GET A QUOTE</a></div>
        <img src={myinsure} className='myinsure' alt='' />
       
        </div>
     </div>
      </div>
    </div>
   </section>
      <section className='policy-faqs' id='faqs'>
        <div className='container'>
<div className='row text-center'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h2>Frequently Asked Questions?</h2>
        <p className='ipsum22'>Got questions about insurance? We have answers. Find information on coverage types,<br /> reducing premiums, filing claims, adjusting coverage, and getting started. </p>
    </div>
</div>
   <div className='row' id='forfaq'>
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
      <div className="accordion" id="myAccordion">
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
        >
 What is IUL Insurance?
        </button>
      </h2>
      <div
        id="collapseOne"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          IUL insurance stands for indexed universal life insurance. It is a type of permanent life insurance that offers flexible premiums, a death benefit, and a cash value that grows based on the performance of a stock market index. IUL insurance can provide protection, growth potential, and tax advantages.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
        >
   Why do I need life insurance?
        </button>
      </h2>
      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>Life insurance can provide financial protection and peace of mind for you and your loved ones. If you have dependents who rely on your income, life insurance can help them maintain their standard of living and achieve their goals in case you are no longer around. Life insurance can also help you leave a legacy for your family or a charity. </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
        >
  How much life insurance do I need?
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          The amount of life insurance you need depends on your personal and financial situation. Some factors to consider are your income, expenses, debts, assets, family size, future plans, and existing coverage.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFour">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
        >
        How can the policy accumulate cash value?
        </button>
      </h2>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>IUL policy pays earnings above the guaranteed minimum rate, that can be credited to the policy based partly on changes in these major stock indexes: the S&P 500® Index, the EURO STOXX 50P Index, and the Hang Seng Index. The different account options must be understood before you decide if this policy is right for you.</p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingFive">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
        >
 Are there tax advantages associated with Life Insurance?
        </button>
      </h2>
      <div
        id="collapseFive"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
     <p><b>Federal income tax-free death benefit:</b> <br />
There can be tax consequences when inheriting certain assets. Life Insurance provide a federal income tax-free death benefit.</p>

<p><b>Tax-deferred earnings</b> <br />
Customers have a greater potential for policy value accumulation since any cash value in the policy accumulates interest tax-deferred.</p>

<p><b>Tax-free withdrawals and loans*:</b> <br />
If the policy value is sufficient, customers can request withdrawals or loans to use for any purpose. Customers enjoy easy access to their policy value. Tax advantages only apply while the policy is in force. It could have adverse tax consequences if the policy is allowed to lapse.</p>

        </div>
      </div>
    </div>
  </div>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
      <div className="accordion" id="myAccordion">
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingSix">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
        >
     What factors influence the cost of insurance?
        </button>
      </h2>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Your premium is determined by a number of factors. This includes your coverage needs and risk factors. Along with the amount of insurance coverage and the particular policy you choose, your age and gender are considered. Personal health history and family health history, such as chronic illness or hereditary diseases, can impact premiums. Personal habits and choices, like smoking or engaging in high-risk hobbies can lead to higher premiums. Your employment may also be considered, as some jobs are higher risk than others.

          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThirteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThirteen"
        >
When or why should I review my policy?
        </button>
      </h2>
      <div
        id="collapseThirteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          If your personal details, like your address or billing information change, you’ll want to update your policy. When your life situation changes, you’ll want to review your policy to ensure it still meets your beneficiary’s needs. Good times to review your policy are if you get married, grow your family, or change jobs.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFourteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFourteen"
        >
How can I lower my insurance premiums?
        </button>
      </h2>
      <div
        id="collapseFourteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          There are several ways to potentially reduce your premiums, such as bundling policies, maintaining a good credit score, and implementing safety measures. We can help identify available discounts and strategies to lower your insurance costs.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFifteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFifteen"
        >
What happens if I need to file a claim?
        </button>
      </h2>
      <div
        id="collapseFifteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We're here to guide you through the claims process. Our dedicated claims team will assist you in gathering the necessary information, filing the claim, and ensuring a smooth resolution. We'll be with you every step of the way.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSixteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSixteen"
        >
How do I get started with insurance coverage?
        </button>
      </h2>
      <div
        id="collapseSixteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Getting started is easy. Simply contact us, and our knowledgeable agents will schedule a consultation to assess your needs and recommend suitable coverage options. We'll guide you through the process from start to finish.
          </p>
        </div>
      </div>
    </div>
  </div>
                </div>
            </div>
        </div>
    </section>      
            <FooterThree />
        </Fragment>
    );
}
 
export default lifeinsurance;