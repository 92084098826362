import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import phonecall from "../../assets/images/insurance/phonecall.png";


const HeaderFour = () => {
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});
        
    return (
        <header className={`header-section style-4 ${headerFiexd ? "header-fixed fadeInUp" : ""}`}>
            {/* <div className={`header-top d-md-none ${socialToggle ? "open" : ""}`}>
                <div className="container">
                    <div className="header-top-area">
                        <Link to="/signup" className="lab-btn me-3"><span>Create Account</span></Link>
                        <Link to="/login">Log In</Link>
                    </div>
                </div>
            </div> */}
            <div className="header-bottom">
                <div className="container">
                    <div className="header-wrapper">
                    <div className="row">
                    <div className="col-xxl-2 col-xl-2 col-lg-1 col-md-3 col-12">
                    <div className="logo-search-acte">
                            <div className="logo">
                                <Link to="/"><img src="assets/images/logo/logo.png" alt="logo" className="sitelogo"/></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-3 col-12">
                       <div className="menu-area">
                            <div className="menu">
                                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                   <li><NavLink to="/">Home</NavLink></li>
                                   <li><a href="/lifeinsurance">life Insurance</a>
                                        <ul className="lab-ul dropdown-menu">
                                          <li className="dropt-list"><NavLink to="/Term-Life-Insurance">Term Life Insurance</NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Whole-Life-Insurance">Whole Life Insurance</NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Universal-Life-Insurance">Universal Life Insurance</NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Indexed-Universal-Life-Insurance">Indexed Universal Life Insurance </NavLink></li>
                                        </ul>
                                    </li>
                                    <li><a href="/financialplanning">Financial Planning</a>
                                      <ul className="lab-ul dropdown-menu">
                                            <li className="dropt-list"><NavLink to="/Wealth-Management">Wealth Management </NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Risk-Management">Risk Management</NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Estate-Management">Estate Management</NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Investment-Management">Investment Management </NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Asset-Protection">Asset Protection</NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Tax-Management">Tax Management</NavLink></li>
                                            <li className="dropt-list"><NavLink to="/Business-Transition-Planning">Business Transition Planning </NavLink></li>
                                        </ul>
                                    </li>
                                    <li><NavLink to="/faq">FAQ</NavLink></li>
                                    <li><NavLink to="/contact-us">Contact</NavLink></li>
                                    <li><NavLink to="/blog">Blog</NavLink></li>
                                </ul>
                            </div>
                           
                         
                            <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="ellepsis-bar d-md-none" onClick={() => setSocialToggle(!socialToggle)}>
                                <i className="icofont-info-square"></i>
                            </div>
                        </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12" id="sidemenucta">
                        <div className="headercnt">
								<div className='chatmsg'>
								<img src={phonecall}  alt='' className='chatmessage' />
							 </div>
							  <div className="box-body">
 							 <h3 className="nav-info-box-title">24 hours every day </h3>
 							 <p><a href="tel:+1 254-334-7192">+1 254-334-7192</a></p>
								</div>
								</div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
 
export default HeaderFour;