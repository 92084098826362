import React, { useRef, useState } from "react";
import patrtner1 from  "../../assets/images/insurance/patrtner1.png";
import patrtner2 from  "../../assets/images/insurance/patrtner2.webp";
import patrtner3 from  "../../assets/images/insurance/patrtner3.png";
import patrtner4 from  "../../assets/images/insurance/patrtner4.png";
import patrtner5 from  "../../assets/images/insurance/patrtner5.png";
import patrtner6 from  "../../assets/images/insurance/patrtner6.png";
import patrtner7 from  "../../assets/images/insurance/patrtner7.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules


const Ourclient = () => {
  return (
    <>
      <div className="Ourclient">
            <div className="container-fluid">
            <div className="Ourclient-wrapper align-items-center">
         <div className="Ourclient-slider">
          <div className="swiper-Ourclient">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={false}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 5,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 5,
                         },
                         700: {
                       slidesPerView: 3,
                       spaceBetween: 5,
                         },
                         768: {
                        slidesPerView: 3,
                       spaceBetween: 0,
                          },
                          1000: {
                    slidesPerView: 4,
                        spaceBetween: 5,
                           },
                           1241: {
                    slidesPerView: 6,
                        spaceBetween: 5,
                           },
                           1440: {
                    slidesPerView: 7,
                        spaceBetween: 5,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
            <img src={patrtner1} alt="" className="patrtner1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={patrtner2} alt="" className="patrtner1" />
          </SwiperSlide>   
          <SwiperSlide>
            <img src={patrtner3} alt="" className="patrtner1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={patrtner4} alt="" className="patrtner1" />
          </SwiperSlide>  
          <SwiperSlide>
            <img src={patrtner5} alt="" className="patrtner1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={patrtner6} alt="" className="patrtner1" />
          </SwiperSlide>   
          <SwiperSlide>
            <img src={patrtner7} alt="" className="patrtner1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={patrtner4} alt="" className="patrtner1" />
          </SwiperSlide>                       
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Ourclient;