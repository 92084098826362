import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";

import { Helmet } from 'react-helmet';
import ourlocation from  '../../src/assets/images/insurance/ourlocation.png';
import finiclapln from "../assets/images/insurance/xg.png";
import email from "../../src/assets/images/insurance/email.png";
import phone from '../../src/assets/images/insurance/phone.png';
import HeaderSix from "../component/layout/header-6";
import React, { useState, useEffect , useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';

function Financialallleadform() {

const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
const [responseStatus, setResponseStatus] = useState(null);
const [recaptchaValue, setRecaptchaValue] = useState(null);
const [city, setCity] = useState("");
const [isFormReady, setIsFormReady] = useState(false);
const [message, setMessage] = useState(""); // New state for textarea
const handleRecaptchaChange = (value) => {
setRecaptchaValue(value);
};
const handleTextareaChange = (e) => {
const inputValue = e.target.value;
setMessage(inputValue);
};
// Function to check if the form is ready to be submitted
const checkFormValidity = () => {
// Add your validation logic here
const isStep1Valid = name && email && phoneNumber && message && city ;
return isStep1Valid;
};
// Update isFormReady whenever the fields are updated
useEffect(() => {
setIsFormReady(checkFormValidity());
}, [ name, email, phoneNumber, message ,city]);
const [isLoading, setIsLoading] = useState(false); // New state for loading
const form = useRef();
// Construct the request body for the emailjs https://www.emailjs.com/
const clearFormFields = () => {
setName("");
setEmail("");
setPhoneNumber("");
setMessage("");
};
const handleSubmit = async (e) => {
e.preventDefault();
// if (!recaptchaValue) {
//   alert("Please select reCAPTCHA before submitting the form.");
//   return;
// }
// Check if the email contains "@mailinator.com"
// if (email.includes("@mailinator.com")) {
//   alert("Emails from mailinator.com are not allowed.");
//   return; // Exit the function and don't proceed to form submission
// }
// setIsLoading(true); // Set loading state to true when submitting
// emailjs.sendForm('service_0ql8mq8', 'template_8ji9ucs', form.current, 'o82aGIEvWnfIcm6WE')
// .then((result) => {
//     console.log(result.text);
// }, (error) => {
//     console.log(error.text);
// });
// Construct the request body based on the step
const requestBody = {
data: {
name:name,
email:email,
phonenumber: phoneNumber,
city:city,
message:message,
},
};
try {
// Send data to Google Sheet API
// const response = await fetch("https://api.apispreadsheets.com/data/KNpVFJnz63xPME2d/", {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//   },
//   body: JSON.stringify(requestBody),
// });
//window.location.href = 'https://mypolicyplanner.com/thankyou';
// // Send data to email script
const requestBody = await fetch("financial.php", {
method: "POST",
body: JSON.stringify(requestBody),
});
// Check if the response is successful (you can modify this condition)
if (requestBody.ok) {
// Reset the form
clearFormFields();
form.current.reset();
}
setIsLoading(false);
} catch (error) {
console.error("An error occurred:", error);
setTimeout(() => setResponseStatus(null), 2000);
setIsLoading(false); // Reset loading state in case of an error
}
};
    return (
        <Fragment>
        <HeaderSix />
        <Helmet>
<title>Elevate Your Finances: Best Financial Planning Solutions</title>
<meta name="description" content="Achieve financial success with our Best Financial Planning Solutions. My Policy Planner is dedicated to securing your future with expertise." />
</Helmet>
<section className='Financialallleadform'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-6- col-xl-6 col-lg-6 col-md-6 col-12'>
        <img src={finiclapln}  className='finiclapln' alt='' />
              </div>
      <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-12'>
      <div className="fromback">
      <h2 className='coverage2 text-center'>Get a quote</h2>
          <div className="divide-heading">
          <span>Let’s help you find coverage.</span>
          </div>
          <form ref={form} onSubmit={handleSubmit}>
            <div>
                {/* Remaining Fields */}
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                    <label>Name</label>
                    <input type="text" name="name" className="form-control"  value={name}onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                    <label>Email</label>
                    <input type="email" name="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>
                  
                  </div>
                  <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                    <label>Phone</label>
                    <input type="tel" name="phone" className="form-control"  value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required/>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                    <label>City</label>
                    <input type="text" name="city" className="form-control"  value={city} onChange={(e) => setCity(e.target.value)} required />
                    </div>
                  </div>
                  <label>Message</label>
                  <textarea name="message" className="form-control"  value={message}
                  onChange={handleTextareaChange} required rows="4" maxLength="200" // Maximum characters (each word counts as one character)
                  />
                  {/* Display character count */}
                  <p>Character Count: {message.length}/200</p>
                  <ReCAPTCHA
                    sitekey="6LfnbusnAAAAAMVoRLl7dNqnOl5aV27PvqTX-Lmm"
                    onChange={handleRecaptchaChange}
                    />
                  <ul className="formlisted">
                    {/* 
                    <li> <input type="submit" className='submit' value="Submit" /> </li>
                    */}
                    {/* <input type="submit" className='submit' value="Submit" disabled={recaptchaValue === null} /> */}
                    <li>
                        {/* Conditionally render loader or submit button */}
                        <input type="submit" className="submit" value="Submit" />
                    </li>
                  </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<section className='red'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-12'>
 
      </div>
    </div>
  </div>
</section>
<section className='leadbottom'>
  <div className='container'>
     <div className='row'>
      <h3>Get in Touch with us</h3>
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </div>
   
  </div>
</section>
<section className='formcontact'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
                 <span className='Journeyspan'>
                  <img src={phone} className='paying'  alt='' />
                  </span>
                  <div class="ctitle-highlight "></div>
               <h3>Call Us for Your Query</h3>
            <p><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></p>
              </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
           <span className='Journeyspan'>
           <img src={email} className='paying'  alt='' />
             </span>
        <div class="ctitle-highlight "></div>
        <h3>Write us Email</h3>
          <p><a href='mailto:info@mypolicyplanner.com'>info@mypolicyplanner.com</a></p>
               </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
            <span className='Journeyspan'>
             <img src={ourlocation} className='paying'  alt='' />
             </span>
            <div class="ctitle-highlight "></div>
            <h3>Address</h3>
            <p>507 Denali Pass, Ste 601 Cedar Park TX 78613</p>
               </div>
      </div>
    </div>
  </div>
</section>  
            <FooterThree />
        </Fragment>
    );
}
 
export default Financialallleadform;



