import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";

import { Helmet } from 'react-helmet';
import Professionals from '../../src/assets/images/insurance/Professionals.png';
import Nonprofits from '../../src/assets/images/insurance/Nonprofits.png';
import IndependentWomen from '../../src/assets/images/insurance/IndependentWomen.png';
import Retirees from "../../src/assets/images/insurance/Retirees.png";
import Client2 from '../component/section/client2';
import risks from '../../src/assets/images/insurance/risks.png';
import capital from '../../src/assets/images/insurance/capital.png';
import financialfreedom from '../../src/assets/images/insurance/financialfreedom.png';
import portfolio from '../../src/assets/images/insurance/portfolio.png';
import retirement from '../../src/assets/images/insurance/retirement.png';
import property from '../../src/assets/images/insurance/property.png';
import HeaderSix from "../component/layout/header-6";

const Financialplanning = () => {
    return (
        <Fragment>
       <HeaderSix />
            <Helmet>
<title>Elevate Your Finances: Best Financial Planning Solutions</title>
<meta name="description" content="Achieve financial success with our Best Financial Planning Solutions. My Policy Planner is dedicated to securing your future with expertise." />
</Helmet>
 {/* Start Slider area  */}
   <section className='Financialplanning'>
      <div className='container'>
        <div className='row'>
          <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='insuranceplan text-center'>
              <h2>Secure Your Financial Future with My Policy Planner:<br />Expert Financial, Estate, and Retirement Planning</h2>
              <p className='ipsum22'> Plan for a prosperous future with My Policy Planner. Our expert team provides comprehensive financial, <br />estate, and retirement planning solutions tailored to your unique needs.</p>
            </div>
          </div>
        </div>
        <div className='row' id='insur-form-sec'>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
              <div className='signle-insurance'>
          <img src={Retirees} className='singleman' alt='' />
          <h3>Retirees & Pre-Retirees</h3>
          <div class="btn-wrapper animated go-top">
          <a class="theme-btn-1 btn btn-effect-1" href="/financial-allleadform">GET MY QUOTE</a></div>
            </div>
                   </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
           <div className='signle-insurance'>
          <img src={Professionals} className='singleman' alt='' />
          <h3>Business Professionals</h3>
          <div class="btn-wrapper animated go-top">
          <a class="theme-btn-1 btn btn-effect-1" href="/financial-allleadform">GET MY QUOTE</a></div>
            </div>
                   </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
             <div className='signle-insurance'>
          <img src={Nonprofits} className='singleman' alt='' />
          <h3>Nonprofits</h3>
          <div class="btn-wrapper animated go-top">
          <a class="theme-btn-1 btn btn-effect-1" href="/financial-allleadform">GET MY QUOTE</a></div>
            </div>
                    </div>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
                  <div className='signle-insurance'>
          <img src={IndependentWomen} className='singleman' alt='' />
          <h3>Independent Women</h3>
          <div class="btn-wrapper animated go-top">
          <a class="theme-btn-1 btn btn-effect-1" href="/financial-allleadform">GET MY QUOTE</a></div>
            </div>
                 </div>
        </div>
      </div>
    </section>
    <section className='Financial'>
    <div className='container'>
        <div className='row'>
             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='insuranceplan text-center'>
              <h2>Empowering Your Financial Journey <br />with My Policy Planner</h2>
              <p className='ipsum22'>My Policy Planner provides tailored financial, estate, and retirement solutions for a prosperous future.<br /> Our expert team ensures peace of mind by guiding you through personalized investment strategies and <br />  effective estate plans. Trust us to navigate the complexities of financial planning and secure <br />your lasting legacy.</p>
            </div>
            <Client2 />
          </div>
            </div>
         </div>
     </section>
 
 <section className='centersec'>
 <div className='yellobnr'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-6 col-xl-7 col-lg-8 col-md-12 col-12' id='lowest'>
        <h2>Let’s Talk About Financial Planning</h2>
        <p>Compare quotes from over 50 life insurance companies and find the best coverage at the lowest price. Whether you need term, whole, or universal life insurance, we help you save and grow money.</p>
        <div class="btn-wrapper animated go-top"><a class="theme-btn-1 btn btn-effect-1" href="tel:+1 844-203-4105">CONTACT US</a></div>
      </div>
    </div>
  </div>
 </div>
 </section>
<section className='Securing'>
  <div className='container'>
  <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
    <h2>Navigating Your Financial Journey,<br /> Securing Your Future with My Policy <br />Planner</h2>
    <p>At My Policy Planner, we understand that financial planning is a lifelong journey. As a trusted wealth management <br />firm, we provide comprehensive services to guide you every step of the  way. Our team of experienced professionals<br /> creates personalized strategies tailored to your unique needs,helping you grow and preserve your wealth. </p>
         </div>
  </div>
  <div className='row' id='their'>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={capital} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Comprehensive Financial Planning</h3>
     <p>My Policy Planner provides holistic financial planning services to help clients navigate their life journey and achieve their financial goals.</p>
    </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={financialfreedom} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Personalized Wealth Management:</h3>
     <p>Our experienced wealth managers offer personalized strategies tailored to each client's unique financial situation, helping them grow and preserve their wealth effectively.</p>
    </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={portfolio} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Investment Portfolio Management</h3>
     <p> We assist clients in building and managing investment portfolios that align with their risk tolerance, financial objectives, and time horizon.</p>
    </div>
    </div>
  </div>
  <div className='row' id='their'>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={retirement} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Retirement Planning</h3>
     <p> My Policy Planner helps clients plan for a comfortable retirement by providing retirement income projections, Social Security optimization strategies, and guidance on retirement account management.</p>
    </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={property} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Estate Planning</h3>
     <p>We help clients create comprehensive estate plans that protect their assets and ensure their wealth is distributed according to their wishes, minimizing taxes and probate.</p>
    </div>
    </div>
  
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='Journey'>
     <span className='Journeyspan'>
      <img src={risks} className='paying'  alt='' />
     </span>
     <div class="ctitle-highlight "></div>
     <h3>Risk Management & Insurance Solutions</h3>
     <p> We assist clients in building and managing investment portfolios that align with their risk tolerance, financial objectives, and time horizon.</p>
    </div>
    </div>
  </div>
  </div>
</section>
  
<section className='policy-faqs' id='faqs'>
        <div className='container'>
<div className='row text-center'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h2>Frequently Asked Questions?</h2>
        <p className='ipsum22'>Got questions about insurance? We have answers. Find information on coverage types,<br /> reducing premiums, filing claims, adjusting coverage, and getting started. </p>
    </div>
</div>
   <div className='row' id='forfaq'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                    <div className="accordion" id="myAccordion">
                    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSeven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSeven"
        >
  How does financial planning benefit me?
        </button>
      </h2>
      <div
        id="collapseSeven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Financial planning provides numerous benefits, including increased financial security, optimized investment strategies, retirement planning, debt management, tax efficiency, and protection against unforeseen events.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEight">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEight"
        >
   Can you help me with retirement planning?
        </button>
      </h2>
      <div
        id="collapseEight"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Yes, we specialize in retirement planning. Our experts will assess your retirement goals, create a tailored plan, and recommend suitable retirement accounts and investment options to secure your future.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingNine">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseNine"
        >
What types of investment options do you offer?
        </button>
      </h2>
      <div
        id="collapseNine"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We offer a wide range of investment options, including stocks, bonds, mutual funds, ETFs, and more. Our team will customize an investment portfolio based on your risk tolerance and financial objectives.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTen"
        >
How do you charge for your financial planning services?
        </button>
      </h2>
      <div
        id="collapseTen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our fees are transparent and agreed upon upfront. We offer fee-only or fee-based services, ensuring no hidden commissions or conflicts of interest.
          </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingEleven">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEleven"
        >
Can you help with estate planning?
        </button>
      </h2>
      <div
        id="collapseEleven"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Absolutely. We provide estate planning services to preserve and distribute your assets according to your wishes while minimizing taxes and probate.
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
      <div className="accordion" id="myAccordion">
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingTweleve">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTweleve"
        >
   How often should I review my financial plan?
        </button>
      </h2>
      <div
        id="collapseTweleve"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We recommend reviewing your financial plan at least annually or whenever significant life events occur, such as marriage, children, job changes, or inheritances.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingEighteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseEighteen"
        >
  What sets your financial planning company apart?
        </button>
      </h2>
      <div
        id="collapseEighteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our firm is dedicated to providing personalized, client-focused financial planning solutions. We are committed to your financial success, with experienced advisors, comprehensive services, and a fiduciary duty to act in your best interest.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingNineteen">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseNineteen"
        >
Are you licensed and registered to offer financial advice?
        </button>
      </h2>
      <div
        id="collapseNineteen"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Yes, our advisors are licensed and registered professionals with the necessary certifications and credentials to provide expert financial advice.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwenty">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwenty"
        >
How can I get started with your financial planning services?
        </button>
      </h2>
      <div
        id="collapseTwenty"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Simply contact us to schedule a consultation. We'll discuss your financial goals, assess your current situation, and create a tailored financial plan to set you on the path to financial success.
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwenty">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwenty"
        >
Is financial planning only for wealthy individuals?
        </button>
      </h2>
      <div
        id="collapseTwenty"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          No, financial planning is essential for individuals of all income levels. It is a proactive approach to managing your finances and achieving financial goals, regardless of your current financial situation.
          </p>
        </div>
      </div>
    </div>
  </div>
                </div>
            </div>
        </div>
    </section>  
            <FooterThree />
        </Fragment>
    );
}
 
export default Financialplanning;



