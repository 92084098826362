import React, { useRef, useState } from "react";
import financialplan from '../../assets/images/insurance/financialplan.png';
import lifeinsurance01 from '../../assets/images/insurance/lifeinsurance01.png';
import healthcare from '../../assets/images/insurance/healthcare.png';
import capital from '../../assets/images/insurance/capital.png';
import hasslefree from '../../assets/images/insurance/hasslefree.png';
import property from '../../assets/images/insurance/property.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Partner = () => {
  return (
    <>
      <div className="Partner">
            <div className="container-fluid">
            <div className="Partner-wrapper align-items-center">
         <div className="Partner-slider">
          <div className="swiper-Partner">
          <Swiper
                              slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 3000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         580: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         700: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         768: {
                        slidesPerView: 2,
                       spaceBetween: 10,
                          },
                          1000: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1200: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1241: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1400: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1440: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1600: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
          <div className="bnrbox">
          <a href="/financialplanning">
            <img src={financialplan} alt="" className="homeinsurance" />
            </a>
                   </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="bnrbox">
          <a href="/lifeinsurance">
            <img src={lifeinsurance01} alt="" className="homeinsurance" />
            </a>
                  </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="bnrbox">
          <a href="/">
            <img src={healthcare} alt="" className="homeinsurance" />
            </a>
                  </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="bnrbox">
            <img src={capital} alt="" className="homeinsurance" />
                  </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="bnrbox">
          <a href="/">
            <img src={hasslefree} alt="" className="homeinsurance" />
            </a>
                  </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="bnrbox">
          <a href="/">
            <img src={property} alt="" className="homeinsurance" />
            </a>
                  </div>
          </SwiperSlide>
         
                 
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Partner;