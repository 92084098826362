import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";
import HeaderFour from "../component/layout/header-4";
import { Helmet } from 'react-helmet';
import ourlocation from  '../../src/assets/images/insurance/ourlocation.png';
import finiclapln from "../assets/images/insurance/finiclapln.webp";
import email from "../../src/assets/images/insurance/email.png";
import phone from '../../src/assets/images/insurance/phone.png';

const BusinessTransitionPlanning = () => {
    return (
        <Fragment>
            <HeaderFour />
            <Helmet>
<title>Seamless Business Transition Planning | My Policy Planner</title>
<meta name="description" content="Ensure a smooth business transition with expert planning and guidance. Trust My Policy Planner for your business's future success." />
</Helmet>
<section className='Financialallleadform'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-6- col-xl-6 col-lg-6 col-md-6 col-12'>
        <img src={finiclapln}  className='finiclapln' alt='' />
              </div>
      <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-12'>
      <div className="fromback">
      <h2 className='coverage2 text-center'>Get a quote</h2>
                     <div className="divide-heading">
                      <span>Let’s help you find coverage.</span>
                      </div>
         <form action="financial.php" method="post" enctype="multipart/form-data">
         <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
            <label>Name</label>
             <input type="text" name="name" className="form-control" required />
             <label>Email</label>
              <input type="email" name="email" className="form-control" required />
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
            <label>Phone</label>
          <input type="tel" name="phone" className="form-control" required />
          <label>City</label>
             <input type="text" name="city" className="form-control" required />
            </div>
            <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
            <label>Message</label>
              <textarea
               name="message"
                rows={4}
              className="form-control"
             defaultValue={" "}
                 />
            </div>
           </div>
         
             
          
                 <input type="submit" className="form-rol" defaultValue="send" />
               </form>
               </div>
      </div>
    </div>
  </div>
</section>
<section className='red'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-12'>
 
      </div>
    </div>
  </div>
</section>
<section className='leadbottom'>
  <div className='container'>
     <div className='row'>
      <h3>Get in Touch with us</h3>
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </div>
   
  </div>
</section>
<section className='formcontact'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
                 <span className='Journeyspan'>
                  <img src={phone} className='paying'  alt='' />
                  </span>
                  <div class="ctitle-highlight "></div>
               <h3>Call Us for Your Query</h3>
            <p><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></p>
              </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
           <span className='Journeyspan'>
           <img src={email} className='paying'  alt='' />
             </span>
        <div class="ctitle-highlight "></div>
        <h3>Write us Email</h3>
          <p><a href='mailto:info@mypolicyplanner.com'>info@mypolicyplanner.com</a></p>
               </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
            <span className='Journeyspan'>
             <img src={ourlocation} className='paying'  alt='' />
             </span>
            <div class="ctitle-highlight "></div>
            <h3>Address</h3>
            <p>507 Denali Pass, Ste 601 Cedar Park TX 78613</p>
               </div>
      </div>
    </div>
  </div>
</section>  
            <FooterThree />
        </Fragment>
    );
}
 
export default BusinessTransitionPlanning;



