import React, { useRef, useState } from "react";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Client2 = () => {
  return (
    <>
      <div className="Financial-planing">
            <div className="container-fluid">
            <div className="Financial-planing-wrapper align-items-center">
         <div className="Financial-planing-slider">
          <div className="swiper-Financial-planing">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 5000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         580: {
                       slidesPerView: 1,
                       spaceBetween: 20,
                         },
                         700: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         768: {
                        slidesPerView: 2,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 2,
                        spaceBetween: 20,
                           },
                           1200: {
                    slidesPerView: 3,
                        spaceBetween: 20,
                           },
                           1241: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1400: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1600: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1920: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
          <SwiperSlide>
          <div className='encompass'>
        <h3 className='Management'><a href='/SecuringYourFinancialFuturewithMyPolicyPlanner'>Wealth Management</a></h3>
        <div className='financial-box'>
        <p>Our wealth management services include personalized financial planning, investment management, retirement & estate planning, tax optimization, and risk management—tailored strategies to achieve your goals and preserve wealth.</p>
      </div>
        </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='encompass'>
        <h3 className='Management'><a href="/SafeguardingYourFinancialFuturewithMyPolicyPlanner">Risk Management</a></h3>
        <div className='financial-box'>
        <p>We provide essential risk management services: risk assessment, mitigation planning, insurance solutions, compliance consulting, and ongoing monitoring to safeguard your financial well-being and ensure business continuity.</p>
      </div>
        </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='encompass'>
        <h3 className='Management'><a href="/InvestmentManagementSecureYourFinancialFuturewithMyPolicyPlanner">Investment Management</a></h3>
        <div className='financial-box'>
        <p>My Policy Planner professionally manages investment portfolios for optimal returns, considering your goals, risk tolerance, and time horizon. Services include asset allocation, diversification, security selection, and ongoing monitoring for top performance.</p>
      </div>
        </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='encompass'>
        <h3 className='Management'><a href="/SafeguardingYourFutureUnderstandingtheImportanceofAssetProtection">Asset Protection</a></h3>
        <div className='financial-box'>
        <p>Safeguard wealth with our asset protection services by my policy planner. We use legal strategies like trusts, LLCs, and insurance to shield assets from risks, lawsuits, and creditors, ensuring a secure financial future.</p>
      </div>
        </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='encompass'>
        <h3 className='Management'><a href="/TaxManagementACrucialAspectofFinancialPlanningwithMyPolicyPlanner">Tax Management</a></h3>
        <div className='financial-box'>
        <p>Tax management services optimize tax positions, benefits, and compliance for individuals and businesses. Minimize tax burdens while maximizing efficiency with strategic planning and expert guidance.</p>
      </div>
        </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='encompass'>
        <h3 className='Management'><a href="/BusinessTransitionPlanningAStrategicGuideforLongTermSuccess">Business Transition Planning</a></h3>
        <div className='financial-box'>
        <p> Business Transition Planning ensures smooth ownership or management handovers. From strategic planning to risk management, we preserve business value and continuity for a sustainable future.</p>
      </div>
        </div>
          </SwiperSlide>
                                   
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Client2;