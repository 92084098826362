import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";
import { Helmet } from 'react-helmet';
import ourlocation from  '../../src/assets/images/insurance/ourlocation.png';
import cont from '../../src/assets/images/insurance/xg.png';
import email from "../../src/assets/images/insurance/email.png";
import phone from '../../src/assets/images/insurance/phone.png';
import HeaderSix from "../component/layout/header-6";
import Mynewform from "../component/section/mynewform";

const UniversalLifeInsurance = () => {
    return (
        <Fragment>
      <HeaderSix />
            <Helmet>
<title>Protection: Best Universal Life Insurance Agency</title>
<meta name="description" content="Explore flexible coverage with the Best Universal Life Insurance Agency. Secure your future with My Policy Planner's expertise." />
</Helmet>
<section className='Newleadform'>
    <div className='container'>
    <div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h2><span className='newhy'>Let’s help you find coverage.</span><br />Get a quote today!</h2>
      </div>
    </div>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='Newleadform-outer-box'>
                <div className="cirlegroup">
  <span className="d-inline-block mr-2" />
  <span className="d-inline-block mr-2" />
  <span className="d-inline-block mr-2" />
</div>
<div className='mainform'>
<div className='container'>
    <div className='row'>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={cont} className='cont' alt='' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <div className='mynewform'>
         <Mynewform />
            {/* <form
          id="msform"
          action="lifeinsurancelead.php"
          method="post"
          encType="multipart/form-data"
        >
    
          <ul id="progressbar" >
            <li className="active" id="account">
              <strong>Step-1</strong>
            </li>
            <li id="payment">
              <strong>Image</strong>
            </li>
           
          </ul>
          
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>{" "}
                       
            
          <fieldset>
            <div className="form-card">
             <div className="row">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" id='formdiv'>
              <p className='Gener'>Select Your Gender</p>
              <div className='radiogroup'>
              <label> Male <br />
              <span>
              <input type="radio" id="male" name="gender" value="male"  />
              </span>
              </label>
              <label> Female <br />
              <span>
                <input type="radio" id="female" name="gender" value="female" />
               </span>
                  </label>
           </div>
           </div>
     
               </div>
            <label>Do you have any existing life insurance policies?</label>
            <select name="existingpolicy" id="existingpolicy">
                     <option value="yes" name="yes">Yes</option>
                    <option value="no" name="no">No</option>
                    </select>
                    <label>Do you have any pre-existing medical conditions?</label>
            <select name="medicalconditions" id="medicalconditions">
                     <option value="yes" name="yes">Yes</option>
                    <option value="no" name="no">No</option>
                    </select>
                  
            </div>{" "}
            <input
              type="button"
              name="next"
              className="next action-button"
              defaultValue="Next"
            />
          </fieldset>
          <fieldset>
            <div className="form-card">
            <label>Are you a smoker?</label>
            <select name="smoker" id="smoker">
                     <option value="yes" name="yes">Yes</option>
                    <option value="no" name="no">No</option>
                    </select>
            <label>Do you engage in any hazardous activities or extreme sports?</label>
            <select name="hazardousactivities" id="hazardousactivities">
                     <option value="yes" name="yes">Yes</option>
                    <option value="no" name="no">No</option>
                    </select>
                                   
            </div>{" "}
            <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              defaultValue="Previous"
            />
            <input
              type="button"
              name="next"
              className="next action-button"
              defaultValue="Next"
            />
          </fieldset>
          <fieldset>
            <div className="form-card">
            <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                    <input type="text" name="name" placeholder="Your Name" className="reg-form" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                    <input type="text" name="occupation" placeholder="Your Occupation" className="reg-form" required />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv'>
                    <input type="number" name="annualincome" placeholder="Annual Income" className="reg-form" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" id='formdiv' >
                    <input type="text" name="city" placeholder="Your City" className="reg-form" required />
                    </div>
                  </div>   
            
                          </div>
       <input type="submit" id='submit' value="Submit" /> 
            
            <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              defaultValue="Previous"
            />
          </fieldset>
        
                     </form> */}
            </div>
            </div>
        </div>
    </div>
</div>
            </div>
            </div>
        </div>
    </div>
</section>
<section className='leadbottom'>
  <div className='container'>
     <div className='row'>
      <h3>Get in Touch with us</h3>
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </div>
   
  </div>
</section>
<section className='formcontact'>
  <div className='container'>
    <div className='row'>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
                 <span className='Journeyspan'>
                  <img src={phone} className='paying'  alt='' />
                  </span>
                  <div class="ctitle-highlight "></div>
               <h3>Call Us for Your Query</h3>
            <p><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></p>
              </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
           <span className='Journeyspan'>
           <img src={email} className='paying'  alt='' />
             </span>
        <div class="ctitle-highlight "></div>
        <h3>Write us Email</h3>
          <p><a href='mailto:info@mypolicyplanner.com'>info@mypolicyplanner.com</a></p>
               </div>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='Journey'>
            <span className='Journeyspan'>
             <img src={ourlocation} className='paying'  alt='' />
             </span>
            <div class="ctitle-highlight "></div>
            <h3>Address</h3>
            <p>507 Denali Pass, Ste 601 Cedar Park TX 78613</p>
               </div>
      </div>
    </div>
  </div>
</section>
            <FooterThree />
        </Fragment>
    );
}
 
export default UniversalLifeInsurance;
