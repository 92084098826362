
const title = "Our Popular Tags";


const tagsList = [
    {
        link: '/',
        text: 'Financial Protection',
    },
    {
        link: '/',
        text: 'Secure Your Future',
    },
    {
        link: '/',
        text: 'Insurance Coverage',
    },
    {
        link: '/',
        text: 'Family Security',
    },
    {
        link: '/',
        text: 'Insure Your Life',
    },
    {
        link: '/',
        text: 'Financial Planning',
    },
    {
        link: '/',
        text: 'Secure Your Legacy ',
    },
    {
        link: '/',
        text: 'Insurance Matters',
    },
    {
        link: '/',
        text: 'Peace Of Mind',
    },
    {
        link: '/',
        text: 'Financial Security',
    },
    {
        link: '/',
        text: 'Life Protection',
    },
    {
        link: '/',
        text: 'Insurance Solutions',
    },
    {
        link: '/',
        text: 'Financial Wellness',
    },
    {
        link: '/',
        text: 'Insure Your Future',
    },
    {
        link: '/',
        text: 'Protect Your Family',
    },
    {
        link: '/',
        text: 'USA Insurance',
    },
    {
        link: '/',
        text: 'USA Life Insurance',
    },
]

const Tags = () => {
    return (
        <div className="widget widget-tags">
            <div className="widget-header">
                <h5 className="title">{title}</h5>
            </div>
            <ul className="widget-wrapper">
                {tagsList.map((val, i) => (
                    <li key={i}><a href={val.link}>{val.text}</a></li>
                ))}
            </ul>
        </div>
    );
}
 
export default Tags;