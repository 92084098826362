import { Component, Fragment } from "react";
import FooterThree from "../component/layout/footer-3";
import { Helmet } from 'react-helmet';
import ourlocation from  '../../src/assets/images/insurance/ourlocation.png';
import email from "../../src/assets/images/insurance/email.png";
import phone from '../../src/assets/images/insurance/phone.png';
import support1 from '../assets/images/insurance/support1.png';
import agentteam from '../assets/images/insurance/agentteam.png';
import HeaderSix from "../component/layout/header-6";
import React, { useState, useEffect , useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
function Mynewform() {
const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
const [responseStatus, setResponseStatus] = useState(null);
const [recaptchaValue, setRecaptchaValue] = useState(null);
const [isFormReady, setIsFormReady] = useState(false);
const [message, setMessage] = useState(""); // New state for textarea
const handleRecaptchaChange = (value) => {
setRecaptchaValue(value);
};
const handleTextareaChange = (e) => {
const inputValue = e.target.value;
setMessage(inputValue);
};
// Function to check if the form is ready to be submitted
const checkFormValidity = () => {
// Add your validation logic here
const isStep1Valid = name && email && phoneNumber && message ;
return isStep1Valid;
};
// Update isFormReady whenever the fields are updated
useEffect(() => {
setIsFormReady(checkFormValidity());
}, [ name, email, phoneNumber, message ]);
const [isLoading, setIsLoading] = useState(false); // New state for loading
const form = useRef();
// Construct the request body for the emailjs https://www.emailjs.com/
const clearFormFields = () => {
setName("");
setEmail("");
setPhoneNumber("");
setMessage("");
};
const handleSubmit = async (e) => {
e.preventDefault();
// if (!recaptchaValue) {
//   alert("Please select reCAPTCHA before submitting the form.");
//   return;
// }
// Check if the email contains "@mailinator.com"
// if (email.includes("@mailinator.com")) {
//   alert("Emails from mailinator.com are not allowed.");
//   return; // Exit the function and don't proceed to form submission
// }
// setIsLoading(true); // Set loading state to true when submitting
// emailjs.sendForm('service_0ql8mq8', 'template_8ji9ucs', form.current, 'o82aGIEvWnfIcm6WE')
// .then((result) => {
//     console.log(result.text);
// }, (error) => {
//     console.log(error.text);
// });
// Construct the request body based on the step
const requestBody = {
data: {
name:name,
email:email,
phonenumber: phoneNumber,
message:message,
},
};
try {
// Send data to Google Sheet API
// const response = await fetch("https://api.apispreadsheets.com/data/KNpVFJnz63xPME2d/", {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//   },
//   body: JSON.stringify(requestBody),
// });
//window.location.href = 'https://mypolicyplanner.com/thankyou';
// // Send data to email script
const requestBody = await fetch("contact.php", {
method: "POST",
body: JSON.stringify(requestBody),
});
// Check if the response is successful (you can modify this condition)
if (requestBody.ok) {
// Reset the form
clearFormFields();
form.current.reset();
}
setIsLoading(false);
} catch (error) {
console.error("An error occurred:", error);
setTimeout(() => setResponseStatus(null), 2000);
setIsLoading(false); // Reset loading state in case of an error
}
};
return (
<Fragment>
   <HeaderSix />
   <Helmet>
      <title>Let's Talk Finance: Contact Us for Expert Guidance</title>
      <meta name="description" content="Ready to take control of your financial journey? Reach out to My Policy Planner for personalized advice and secure your future." />
   </Helmet>
   <section className='contactpge'>
      <div className='container'>
         <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
               <h2>Contact Us</h2>
               <p>Reach out to secure your future! Contact us now for expert  solutions tailored to <br />your needs. Peace of mind just a message away.</p>
            </div>
         </div>
      </div>
   </section>
   <section className='contct-below'>
      <div className='container'>
         <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
               <div className='ctabnr'>
                  <span className='ctabnrimg'>
                  <img src={support1} alt="" className="support1" />
                  </span>
                  <span className='ctabnrone'>Chat with an agent? Call now: </span>
                  <span className='ctabnrtwo'><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></span>
                  <img src={agentteam} className='newagent' alt='' />
               </div>
            </div>
         </div>
      </div>
   </section>
   <section className='contactdetail'>
      <div className='container'>
         <div className='row' id='query-form'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12'>
               <div className='row'>
                  <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='PascalCase'>
                     <div className='calling-dtail'>
                        <div className='Journey'>
                           <span className='Journeyspan'>
                           <img src={phone} className='paying'  alt='' />
                           </span>
                           <div class="ctitle-highlight "></div>
                           <h3>Call Us for Your Query</h3>
                           <p><a href='tel:+1 844-203-4105'>+1 844-203-4105</a></p>
                        </div>
                     </div>
                     <div className='calling-dtail'>
                        <div className='Journey'>
                           <span className='Journeyspan'>
                           <img src={email} className='paying'  alt='' />
                           </span>
                           <div class="ctitle-highlight "></div>
                           <h3>Write us Email</h3>
                           <p><a href='mailto:info@mypolicyplanner.com'>info@mypolicyplanner.com</a></p>
                        </div>
                     </div>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12' id='PascalCase'>
                     <div className='calling-dtail'>
                        <div className='Journey'>
                           <span className='Journeyspan'>
                           <img src={ourlocation} className='paying'  alt='' />
                           </span>
                           <div class="ctitle-highlight "></div>
                           <h3>Address</h3>
                           <p>507 Denali Pass, Ste 601 Cedar Park TX 78613</p>
                        </div>
                     </div>
                     <div className="widget ltn__social-media-widget">
                        <h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
                        <div className="ltn__social-media-2">
                           <ul>
                              <li><a href="https://www.facebook.com/mypolicyplanner/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                              <li><a href="https://twitter.com/mypolicyplanner" title="Twitter"><i className="fab fa-twitter" /></a></li>
                              <li><a href="https://www.instagram.com/mypolicyplanner/" title="Instagram"><i className="fab fa-instagram" /></a></li>
                              <li><a href="https://www.tiktok.com/@my_policyplanner" title="Tiktok"><i class="fa-brands fa-tiktok"></i></a></li>
                              <li><a href="https://www.linkedin.com/company/mypolicyplanner/" title="Linkedin"><i class="fa-brands fa-linkedin-in"></i></a></li>
                              <li><a href="https://www.pinterest.com/mypolicyplanner/" title="Pintrest"><i class="fa-brands fa-pinterest-p"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12'>
               <div className="fromback">
                  <h2>Get in Touch</h2>
                  <form ref={form} onSubmit={handleSubmit}>
                     <div>
                        {/* Remaining Fields */}
                        <label>Name</label>
                        <input type="text" name="name" className="form-control" value={name}onChange={(e) => setName(e.target.value)} required />
                        <label>Email</label>
                        <input type="email" name="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        <label>Phone</label>
                        <input type="tel" name="phone" className="form-control"  value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required/>
                        <label>Message</label>
                        <textarea name="message" className="form-control"  value={message}
                        onChange={handleTextareaChange} required rows="4" maxLength="200" // Maximum characters (each word counts as one character)
                        />
                        {/* Display character count */}
                        <p>Character Count: {message.length}/200</p>
                        <ReCAPTCHA
                           sitekey="6LfnbusnAAAAAMVoRLl7dNqnOl5aV27PvqTX-Lmm"
                           onChange={handleRecaptchaChange}
                           />
                        <ul className="formlisted">
                           {/* 
                           <li> <input type="submit" className='submit' value="Submit" /> </li>
                           */}
                           {/* <input type="submit" className='submit' value="Submit" disabled={recaptchaValue === null} /> */}
                           <li>
                              {/* Conditionally render loader or submit button */}
                              <input type="submit" className="submit" value="Submit" />
                           </li>
                        </ul>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </section>
   <FooterThree />
</Fragment>
);
}
export default Mynewform;