
import { Link } from "react-router-dom";
import React from "react";
import logo2 from "../../assets/images/logo/logo-2.png";
import Social from "./social";



const title = "About Edukon";
const desc = "Eduaid theme number one world class university in the world There are student are studing always in this university for all time.";
const courseTitle = "Courses";
const quickTitle = "Quick Links";
const tweetTitle = "Recent Tweets";

const addressList = [
    {
        iconName: 'icofont-google-map',
        text: 'New Elefent Road, Dhaka.',
    },
    {
        iconName: 'icofont-phone',
        text: '+880 123 456 789',
    },
    {
        iconName: 'icofont-envelope',
        text: 'info@Edukon.com',
    },
]

const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: '#',
        className: 'facebook',
    },
    {
        iconName: 'icofont-twitter',
        siteLink: '#',
        className: 'twitter',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: '#',
        className: 'linkedin',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: '#',
        className: 'instagram',
    },
    {
        iconName: 'icofont-pinterest',
        siteLink: '#',
        className: 'pinterest',
    },
]

const courseList = [
    {
        text: 'All Courses',
        link: '#',
    },
    {
        text: 'Forms and Admision materials',
        link: '#',
    },
    {
        text: 'Professional Courses',
        link: '#',
    },
    {
        text: 'Course Outline',
        link: '#',
    },
    {
        text: 'Policy',
        link: '#',
    },
    {
        text: 'FAQs',
        link: '#',
    },
    {
        text: 'Online Course',
        link: '#',
    },
]

const quickList = [
    {
        text: 'Summer Sessions',
        link: '#',
    },
    {
        text: 'Events',
        link: '#',
    },
    {
        text: 'Gallery',
        link: '#',
    },
    {
        text: 'Forums',
        link: '#',
    },
    {
        text: 'Privacy Policy',
        link: '#',
    },
    {
        text: 'Terms of Use',
        link: '#',
    },
]

const tweetList = [
    {
        iconName: 'icofont-twitter',
        desc: <p>Aminur islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your item, 50% Big Sale Offer !!</p>,
    },
    {
        iconName: 'icofont-twitter',
        desc: <p>Somrat islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your item, 50% Big Sale Offer !!</p>,
    },
]

const footerbottomList = [
    {
        text: 'Faculty',
        link: '#',
    },
    {
        text: 'Staff',
        link: '#',
    },
    {
        text: 'Students',
        link: '#',
    },
    {
        text: 'Alumni',
        link: '#',
    },
]


const FooterThree = () => {
    return (
        <footer className="style-2">
            <div className="footer-top dark-view padding-tb">
                <div className="container">
                    <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1 justify-content-center">
                        <div className="col">
                            <div className="footer-item our-address">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                    <img src={logo2} alt="" className="logo-2" />
                                    <p>Discover Boundless Protection and Prosperity at My Policy Planner - Your Trusted Insurance and Financial Guides!</p>
                                        <Social />
                                                                            </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Important Links</h4>
				            <div className="footer-menu go-top">
				              <ul>
							 	<li><a href="/financialplanning">Financial Planning</a></li>
								<li><a href="/lifeinsurance">Life Insurance</a></li>
								<li><a href="/faq">FAQ</a></li>
								<li><a href="/contactus">Contact</a></li>
								</ul>
				            </div>
				          </div>
				        </div>
                        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <h4 className="footer-title">Get in Touch</h4>
							<div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
                                  <i class="fa fa-map-marker" aria-hidden="true"></i>
				                  </div>
				                  <div className="footer-address-info">
				                    <p>507 Denali Pass, Ste 601 Cedar Park TX 78613</p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
                                  <i class="fa fa-phone" aria-hidden="true"></i>
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="tel:+1 844 203-4105">+1 844 203-4105</a></p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
                                  <i class="fa-regular fa-envelope"></i>
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="mailto:info@mypolicyplanner.com">info@mypolicyplanner.com</a></p>
				                  </div>
				                </li>
				              </ul>
				            </div>
				          </div>
				        </div>
                        <div className="col-xl-4 col-md-6 col-sm-12 col-12">
				          <div className="footer-widget footer-newsletter-widget">
				            <h4 className="footer-title">Newsletter</h4>
				            <p>Subscribe to our weekly Newsletter and receive updates via email.</p>
					            <div className="footer-newsletter">
				              <form  action="newsletter.php"
                     method="post"
                    encType="multipart/form-data"
                    id="myForm">
							  <input className='bnnrfrm' name='email' type="email" placeholder="Enter Your Email Address" required />
				                <div className="btn-wrapper">
				                  <button className="theme-btn-1 btn" type="submit"><i className="fas fa-location-arrow" /></button>
				                </div>
				              </form>
				            </div>
				           				          </div>
				        </div>
                        {/* <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>Important Links</h4>
                                        </div>
                                        <div className="content">
                                        <div className="footer-menu go-top">
				              <ul>
							 	<li><a href="/financialplanning">Financial Planning</a></li>
								<li><a href="/lifeinsurance">life Insurance</a></li>
								<li><a href="/faq">FAQ</a></li>
								<li><a href="/contactus">Contact</a></li>
								</ul>
				            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{quickTitle}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                                {quickList.map((val, i) => (
                                                    <li key={i}><a href={val.link}>{val.text}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col">
                            <div className="footer-item twitter-post">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{tweetTitle}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                                {tweetList.map((val, i) => (
                                                    <li key={i}>
                                                        <i className={val.iconName}></i>
                                                        {val.desc}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
			<div className="container-fluid ltn__border-top-2">
				<div className="row text-center">
				<div className=" col-xxl-12 col-lg-12 col-lg-12 col-md-12 col-12">
					<div className="ltn__copyright-design clearfix">
					<p>©<a href="/">Mypolicyplanner</a> 2023 @ All rights reserved </p>
					</div>
				</div>
			
				</div>
			</div>
			</div>
            {/* <div className="footer-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <p>&copy; 2022 <Link to="/">Edukon</Link> Designed by <a href="https://themeforest.net/user/CodexCoder" target="_blank">CodexCoder</a> </p>
                        <div className="footer-bottom-list">
                            {footerbottomList.map((val, i) => (
                                <a href={val.link} key={i}>{val.text}</a>
                            ))}
                        </div>
                    </div>
                </div>
            </div> */}
        </footer>
    );
}
 
export default FooterThree;